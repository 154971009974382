@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.broker, .broker-void {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: calc(25% - 10px);
    max-width: 25%;
    background: $color-page-background;
    border-radius: 24px;
    padding: 24px;
    flex: 1;

    @include media-table-large {
        min-width: calc(33% - 10px);
        max-width: 33%;
        border-radius: 16px;
    }

    @include media-tablet-portrait {
        padding: 16px;
        min-width: calc(50% - 10px);
        max-width: 50%;
        border-radius: 12px;
    }

    @include media-mobile {
        min-width: 100%;
        max-width: unset;
    }
}

.broker {
    gap: 24px;

    @include media-tablet-portrait {
        gap: 20px;
    }

    @include media-mobile {
        gap: 24px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    &__icon {
        position: relative;
        width: 40px;
        height: 40px;

        @include media-tablet-portrait {
            width: 32px;
            height: 32px;
        }

        @include media-tablet-portrait {
            width: 28px;
            height: 28px;
        }

        @include media-mobile {
            width: 32px;
            height: 32px;
        }
    }

    &__title {
        letter-spacing: 0;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        font-weight: 600;

        @include media-tablet-portrait {
            font-size: 16px;
        }

        @include media-tablet-portrait {
            font-size: 14px;
        }

        @include media-mobile {
            font-size: 20px;
        }
    }

    &__button {
        width: 100%;

        @include media-mobile {
            padding: 9px 16px;
        }

        &-container {
            align-self: center;
            max-width: 250px;
            width: 100%;
        }
    }
}

.broker-void {
    gap: 12px;
    justify-content: center;
    align-items: center;

    @include media-mobile {
        display: none;
    }

    &__icon {
        position: relative;
        width: 32px;
        height: 32px;
    }

    &__text {
        color: #8B8D97;
        margin: 0;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        text-align: center;
        max-width: 24ch;
        letter-spacing: 0;
    }
}
